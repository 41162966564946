<template>
    <div class="loadingAction" v-if="loading">
        正在加载中，请稍后！
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>
<style lang="less" scoped>
    .loadingAction {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        background: rgba(0, 0, 0, .6);
        color: #fff;
        line-height: 30px;
        border-radius: 4px;
        width: 200px;
        text-align: center;
    }
</style>